import { isPast, isFuture, format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDate = (dateString: string = '', dateFormat: string = 'MMMM d, y'): string => {
    if (dateString) {
        const date = parseISO(dateString);
        return formatInTimeZone(date, 'UTC', dateFormat);
    } else {
        return '';
    }
};

export const formatDateIntnl = (dateString: string = '', pageLanguage: string = ''): string => {
    let dateForm = 'en-EN';
    if (pageLanguage.length) {
        dateForm = `${pageLanguage}-${pageLanguage.toUpperCase()}`;
    }
    if (dateString) {
        const date = parseISO(dateString);
        return new Intl.DateTimeFormat(dateForm, {
            timeZone: 'UTC',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    } else {
        return '';
    }
};

export const dateRange = (start: string, end: string, dateFormat: string = 'MMMM d, yyyy'): string => {
    const startDate = formatDate(start, dateFormat);
    const endDate = formatDate(end, dateFormat);
    return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
};

export const timeRange = (start: string, end: string, timeFormat: string = 'h:mm a'): string => {
    const startUTC = parseISO(start);
    const endUTC = parseISO(end);
    return `${formatInTimeZone(startUTC, 'UTC', timeFormat)} - ${formatInTimeZone(endUTC, 'UTC', timeFormat)}`;
};

export const dateRelationEvents = (dateToCheck: string): 'past' | 'future' | undefined => {
    const addDaysToDate = (date: string, n: number): Date => {
        const d = new Date(date);
        d.setDate(d.getDate() + n);
        return d;
    };
    const date = addDaysToDate(dateToCheck, 1);
    if (isPast(date)) return 'past';
    if (isFuture(date)) return 'future';
};

export const dateRelation = (dateToCheck: string): 'past' | 'future' | undefined => {
    const date = parseISO(dateToCheck);
    if (isPast(date)) return 'past';
    if (isFuture(date)) return 'future';
};

export const formatICalDate = (dateString: string = '', eventType: 'conference' | 'event'): number[] | undefined => {
    const dateFormat = 'yyyy,M,d';
    const dateTimeFormat = 'yyyy,M,d,H,m';
    const date = parseISO(dateString);

    switch (eventType) {
        case 'conference':
            return format(date, dateFormat)
                .split(',')
                .map((x) => Number(x));
        case 'event':
            return format(date, dateTimeFormat)
                .split(',')
                .map((x) => Number(x));
    }
};

export const formatTime = (dateString: string = '', timeFormat: string = 'h:mm a'): string => {
    const date = parseISO(dateString);
    return formatInTimeZone(date, 'UTC', timeFormat);
};

export const parseDate = (dateString: string): { day: number; month: string; year: number } => {
    const date = parseISO(dateString);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];
    return {
        day: date.getUTCDate(),
        month: monthNames[date.getUTCMonth()],
        year: date.getUTCFullYear(),
    };
};
